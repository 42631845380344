import { IncomingMessage, ServerResponse } from 'http';

import jsCookie from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

import { getServerCookie, setServerCookie } from '~/src/common/services/cookies';
import {
  FeatureFlagContextPayload,
  FeatureFlagDeviceContextContent,
  FeatureFlagMultiContexts,
  FeatureFlagUserContextContent,
} from '~/src/common/services/FeatureFlag/types';

export const FLAGS = {
  'paiement-paypal': false,
  'mcom-monitoring-perfs': 0,
  'landing-v2-header-action': 'LOGIN',
  'widgets-trustpilot': false,
  'complete-order': false,
  'copernic-chatbot': false,
  'amelioration-des-recettes': false,
  'optimisations-visuelles-page-produit': false,
  'double-upsell-apres-panier': false,
  'ecom-new-delivery-process': false,
  'recettes-panier': false,
  'cross-sell-produits-complementaires': false,
  'cross-sell-produits-similaires': false,
  'wording-pre-auth': false,
};

const FEATURE_FLAG_USER_KEY = 'feature_flag_unique_device_id';

export const getUserKey = () => jsCookie.get(FEATURE_FLAG_USER_KEY);

export const setUserKey = (value?: string) => {
  const userKey = value ?? getUserKey() ?? uuidv4();
  jsCookie.set(FEATURE_FLAG_USER_KEY, userKey, { expires: 365 });
  return userKey;
};

const getServerUserKey = (req: IncomingMessage) => getServerCookie(FEATURE_FLAG_USER_KEY, req);

export const setServerUserKey = (req: IncomingMessage, res: ServerResponse) => {
  const userKey = getServerUserKey(req) ?? uuidv4();
  setServerCookie({ name: FEATURE_FLAG_USER_KEY, value: userKey }, res);

  return userKey;
};

export const getInitialContext = ({
  user,
  cart,
  deviceKey,
}: FeatureFlagContextPayload & { deviceKey: string }) => {
  const userContext: FeatureFlagUserContextContent | undefined = user
    ? {
        key: user.id,
        email: user.email,
      }
    : undefined;

  const deviceContext: FeatureFlagDeviceContextContent = {
    key: deviceKey,
    origin: 'web',
    version: process.env.NEXT_PUBLIC_APP_VERSION,
  };

  const context: FeatureFlagMultiContexts = {
    kind: 'multi',
    device: deviceContext,
  };

  if (userContext) {
    context.user = userContext;
  }

  if (cart?.delivery?.deliveryZoneId && cart.delivery.timeSlotId) {
    context.delivery = {
      key: cart.delivery.timeSlotId,
      shop: cart.delivery.shopId,
      deliveryZone: cart.delivery.deliveryZoneId,
    };
  }

  return context;
};
