import styled, { css } from 'styled-components';

import Button from '~/src/common/components/Button';
import Icon from '~/src/common/components/Icon';
import Link from '~/src/common/components/Link';
import { FAMILY_MENU_HEIGHT } from '~/src/common/components/Navigation/constants';
import TypeTag from '~/src/common/components/Recipe/TypeTag/TypeTag';
import { H3 } from '~/src/common/components/Typography';
import { hideScrollbar } from '~/src/common/themes/main/shared-styles';
import { ProductGridArea } from '~/src/screens/CatalogContent/ContentScreen/ProductScreen/constants';
import { NAV_HEIGHT, media } from '~/src/styles-constants';

const BREADCRUMBS_HEIGHT = 32;
const STICKY_TABLET = NAV_HEIGHT + BREADCRUMBS_HEIGHT;
const STICKY_DESKTOP = NAV_HEIGHT + FAMILY_MENU_HEIGHT + BREADCRUMBS_HEIGHT;

export const StickyContent = styled.div`
  padding: 0 16px;
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 24px;

  ${media.M`
    padding: 0;
    position: sticky;
    top: ${STICKY_TABLET}px;
  `}

  ${media.XL`
    top: ${STICKY_DESKTOP}px;
  `}
`;

export const StickyContentGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StickyContentItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
  gap: 8px;
`;

export const Content = styled.div`
  position: relative;
  max-width: 1200px;
  padding: 0;
  margin: 0 -11px; /* Du fait du grid-gap */

  /* Le header à une margin bottom de 8px commune sur toute les pages hormis la page produit/recette */
  margin-top: -8px;

  ${media.M`
    margin: auto;
  `}
`;

export const GridContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;

  ${media.M`
    grid-row-gap: 24px;
    grid-column-gap: 40px;
    grid-template-columns: 1fr 406px;
  `}
`;

export const ImageSliderContainer = styled.div`
  height: 100%;
  width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  grid-area: ${ProductGridArea.Gallery};

  ${media.M`
    grid-column: 1;
    grid-row: 2;
    max-height: 100%;
  `}
`;

export const NavigationContainer = styled.div`
  display: none;

  grid-area: ${ProductGridArea.Breadcrumbs};

  ${media.M`
    display: flex;
    align-items: center;
    grid-column: 1;
    grid-row: 1;
    margin-bottom: -26px;  // Du fait du grid-gap
    margin-top: 8px;
  `}
`;

export const GoBackButtonOld = styled(Button)`
  height: auto;
  min-height: auto;
  margin: 0;
  display: none;

  span {
    padding: 0 !important;
    display: flex;
    align-items: center;
    text-decoration: none;
  }

  ${media.M`
    display: initial;
  `}
`;

export const GoBackButton = styled.button`
  display: none;

  ${media.M`
    padding: 0;
    margin-left: 8px;
    border: none;
    background: ${({ theme }) => theme.palette.common.TRANSPARENT};
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;

    color: ${({ theme }) => theme.palette.common.PRIMARY};

    &:hover {
      color: ${({ theme }) => theme.palette.common.PRIMARY_HOVER};
    }

      display: flex;
      align-items: center;

      span:last-child {
        margin-left: 8px;
      }
  `}
`;

export const GoBackSpan = styled.span`
  margin-left: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`;

const breadcrumbsStyle = css`
  & span {
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  & > span:last-child {
    color: ${({ theme }) => theme.palette.common.DISABLED};
  }

  ${Icon} {
    position: relative;
    font-size: 16px;
  }
`;

export const BreadcrumbsContainer = styled.div`
  ${breadcrumbsStyle};
  display: none;
  align-items: center;
  padding: 0 16px;

  overflow-x: scroll;
  ${hideScrollbar}

  ${media.M`
    display: flex;
  `}
`;

export const MobileBreadcrumbsContainer = styled.div`
  ${breadcrumbsStyle};
  display: flex;
  align-items: center;

  white-space: nowrap;
  padding: 0 16px 8px;

  overflow: auto;
  ${hideScrollbar}

  ${media.M`
    display: none;
  `}
`;

export const BreadcrumbLink = styled(Link)`
  display: flex;
  align-items: center;

  font-size: 12px;
  line-height: 16px;

  &,
  &:hover {
    color: inherit;
  }
`;

export const ContentNotFound = styled(H3)`
  margin: 50px auto;
  text-align: center;

  grid-column: 1 / 3;
`;

export const CatalogItemName = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.common.TEXTS};
`;

export const tagStyle = css`
  padding: 0 4px;
  white-space: pre;

  font-weight: 500;
  font-size: 12px;
  line-height: 24px;

  border-radius: 4px;

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export const LightGreenTag = styled.div`
  display: inline-flex;
  align-items: center;
  ${tagStyle};

  background-color: ${({ theme }) => theme.palette.common.LIGHT_GREEN};
  color: ${({ theme }) => theme.palette.common.PRIMARY};

  & ${Icon} {
    margin-right: 4px;
    line-height: 24px;
  }
`;

export const StyledTypeTag = styled(TypeTag)`
  margin: 0 8px 8px 0;
`;
